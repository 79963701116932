import React, { useEffect, useState } from "react"
import HomeSecondaryTitle from "./home-secondary-title"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

gsap.registerPlugin(ScrollTrigger);

const HomeRecentWorks = () => {

  // const { loading, error, data } = useStaticQuery(imageQuery)
  const staticQuery: any = useStaticQuery(query);

  // gatsbyImages = staticQuery.allFile.edges.map((image: any) => {
  //   // const _image = getImage(image.node.childImageSharp);

  //   return image.node
  // });

  const _projects: any[] = staticQuery.allStrapiProject.edges.map((el: any) => {
    return {
      nodeImage: el.node.picture.localFile,
      priority: el.node.priority,
      placeholder: el.node.title,
      id: el.node.id,
      link: el.node.link
    };
  }).sort((a: any, b: any) => a.priority && b.priority && (a.priority - b.priority))

  const _pageText: { projectsTitle: string } = staticQuery.strapiHome;

  const [pageText, setPageText] = useState<{projectsTitle: string}>(_pageText);
  const [show, setShow] = useState<boolean>(false);
  const [projects, setProjects] = useState<any[]>(_projects);

  // //ci deve essere un modo migliore di fare sta cosa ma non va mai niente su sto coso
  useEffect(() => {

    const slider: any = document.getElementById('custom-container');
    let mouseDown: boolean = false;
    let startX: number = 0;
    let scrollLeft: number = 0;

    const startDragging = (e: any) => {
      // console.log('startDragging', new Date());

      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;

    };

    const stopDragging = (event: any) => {
      // console.log('stopDragging', new Date());

      mouseDown = false;

    };

    slider.addEventListener('mousemove', (e: any) => {
      // console.log('mousemove', new Date());

      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;

    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
    
  }, [])

  return (

    <div className="flex flex-col h-screen px-4 lg:px-32 md:px-16">
      <div className="my-20">
        <HomeSecondaryTitle smallTitle="PROJECTS" mainTitle={pageText?.projectsTitle}/>
      </div>
      <div className="flex items-stretch overflow-x-scroll overflow-y-hidden" id="custom-container">
        <div className="flex flex-row justify-center mb-5 min-w-min ">
          {/* <div className="w-1/12 h-full"></div> */}
          {projects.map((image: any) => {
            const _image: IGatsbyImageData = image?.nodeImage && getImage(image.nodeImage);
            const _link: string = image.link;
            return (
              image &&
              <div className="h-full mr-5 btn-like panel img-container-width" key={image.id}>
                {/* <a href={_link} target="_blank"> */}
                  <GatsbyImage image={_image} alt={'test'} className="w-full h-full" />
               {/*  </a> */}
              </div>
            )
          })
          }
          {/* {staticQuery.allFile.edges.filter((el: any) => (el.node.relativePath as string).includes('projects/') || null).map((image: any) => {
            const _image: any = getImage(image.node);
            console.log('_image', _image, image);
            return (
              <div className="h-full mr-5 btn-like panel w-screen-50">
                <GatsbyImage image={_image} alt={'test'} className="w-full h-full" />
              </div>
            )
          })
          } */}
          {/* {
            staticQuery.allFile.edges.map(
              (edges: any, index: number) => {
                let _image : any = getImage(staticQuery.allFile.edges[index].node.childImageSharp);
                console.log('_image', staticQuery.allFile.edges[index]);
                return (
                  <div className="h-full mr-5 btn-like panel bg-cardinal">
                    <GatsbyImage image={_image} alt={'test'} />
                  </div>
                )
              })
          } */}
          {/* {staticImages.map((image, index) => (
            <div className="h-full mr-5 btn-like panel bg-cardinal">
              <span className="text-white">{image}</span>
            </div>
          ))} */}
        </div>
      </div>
      {/* </Parallax> */}
      {/* <div className="h-40"></div> */}
    </div>
    
  )
}

export const query = graphql`
query {
  allStrapiProject {
    edges {
      node {
        id
        title
        link
        priority
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  strapiHome {
    projectsTitle
  }

}

  `;

// allFile(filter: { internal: { mediaType: { regex: "/image/", ne: "svg" } } }) {
//       edges {
//         node {
//       id
//       relativePath
//       extension
//           childImageSharp {
//         id
//         gatsbyImageData(layout: CONSTRAINED)
//       }
//     }
//   }
// }

// fluid(maxWidth: 2400) {
//             ...GatsbyImageSharpFluid
// }


export default HomeRecentWorks;

//     query {
//   images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
//         edges {
//           node {
//         relativePath
//         extension
//             childImageSharp {
//           fluid(maxWidth: 600) {
//                 ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// }


    //   let sections = gsap.utils.toArray(".panel");
    //   console.log('sections', sections);

    //   // let scroller = ScrollSmoother.create({});

    //   gsap.to(sections, {
    //     xPercent: -100 * (sections.length - 1),
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: ".start-rw-animation",
    //       // start: 'top center',
    //       start: "center center",
    //       markers: true,
    //       toggleActions: "restart pause reverse pause",
    //       // pin: ".testPin",
    //       // scrub: 1,
    //       snap: 1 / (sections.length - 1),
    //       end: () => "+="+ document.getElementById('custom-container')?.offsetWidth
    //     },
    //     onComplete: () => {
    //       console.log('onComplete');
    //       // scroller.paused(false);
    //     },
    //     onStart: () => {
    //       console.log('onStart')
    //       // scroller.paused(true);
    //     },
    //   });